import { useEffect,useState } from "react"

export const RfiScreens=(props)=>{
const [PricingList,setPricingList]=useState([])

useEffect(()=>{
var temp=[1,2,3]
setPricingList(temp)
},[])

return(
    <div className="row p-4 mainGraphRow CustomSizedRow m-0">
        <div className="col-lg-12 col-12 d-flex justify-content-center">
            <h4>What could a REFI Save you in interest?</h4>
        </div>
        <p className="col-lg-12 col-12 d-flex justify-content-center bottomDivider"></p>

        <div className="col-lg-12 col-12 d-flex justify-content-center">
            <p>Based on Rates of January, 2019</p>
        </div>
        <div className="col-lg-12 col-12 d-flex justify-content-center ">
            {PricingList.map((item,key)=>{
                return(<PricingTable key={key} MainItem={item}/>)
            })}
        </div>

        <div className="col-lg-12 col-12 d-flex justify-content-center">
            <button className="btn btn-warning">Learn more about refinancing</button>
        </div>

    </div>
)
}



const PricingTable=(props)=>{
    return(
        <div className="p-4">
            <div className="row pricingElement">
                <div className="col-lg-12 col-12 d-flex justify-content-center">
                    <button className="btn btn-primary pricingBtnHeader">15% Off</button>
                </div>
                <div className="col-lg-12 col-12 d-flex justify-content-center p-3">
                    <h2 className="text-warning">$34,000</h2>
                </div>
                <div className="col-lg-12 col-12 d-flex justify-content-center">
                <div className="row p-4">
                    <p className="col-lg-12 col-12 d-flex  justify-content-center">
                        4%
                    </p>
                    <p className="col-lg-12 col-12">
                        <i className="fa fa-check text-success"></i><span> Long Term Savings</span>
                    </p>
                    <p className="col-lg-12 col-12">
                    <i className="fa fa-xmark mainPrimaryColor"></i><span> Payment Goes up by $766/month</span>
                    </p>
                </div>
                </div>
            </div>
        </div>
    )
}