export const Header = () => {
  return (
      // <nav className="navbar navbar-expand-lg navbar-light bg-light CustomNav">
      //   <a className="navbar-brand textColor brandItem" href="#" >
      //     home<strong>report</strong>
      //   </a>
      //   <button
      //     className="navbar-toggler"
      //     type="button"
      //     data-toggle="collapse"
      //     data-target="#navbarTogglerDemo02"
      //     aria-controls="navbarTogglerDemo02"
      //     aria-expanded="false"
      //     aria-label="Toggle navigation"
      //   >
      //     <span className="navbar-toggler-icon textColor"></span>
      //   </button>

      //   <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
      //     <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
      //       <li className="nav-item active activeNavItem">
      //         <a className="nav-link textColor" href="#">
      //           Get Started <span className="sr-only">(current)</span>
      //         </a>
      //       </li>
      //       <li className="nav-item">
      //         <a className="nav-link textColor" href="#">
      //           About
      //         </a>
      //       </li>
      //       <li className="nav-item">
      //         {/* <a className="nav-link disabled" href="#"> */}
      //         <a className="nav-link textColor" href="#">
      //           Contact
      //         </a>
      //       </li>
      //     </ul>
      //     <form className="form-inline my-2 my-lg-0">
      //       <button
      //         className="btn btn-warning my-2 my-sm-0 textColor"
      //         type="submit"
      //       >
      //         Login
      //       </button>
      //     </form>
      //   </div>
      // </nav>

      <div className="CustomNav d-flex justify-content-center">
        <span><strong>houzd</strong>report</span>
      </div>




  );
};
