
import { HouzdVerifier,HouzdContactDetails,HouzdUpdateAVM,HouzdUpdateContact, HouzdFetchHPIDetails } from "./Endpoints"
import { Fetch_Auth_Code } from "./GlobalFunctions"


export const HouzdVerifier_Call=()=>{
return GetCall(HouzdVerifier)
}

export const HouzdContactDetails_Call=(ContactID)=>{
var Body={
  "ContactID": ContactID
}


return AuthPostCall(HouzdContactDetails,Body);

}

export const HouzdUpdateAVM_Call=(Body)=>{
  return AuthPostCall(HouzdUpdateAVM,Body);

}
export const HouzdUpdateContact_Call=(Body)=>{
  return AuthPostCall(HouzdUpdateContact,Body)
}

export const HouzdRetrieve_HPI=(Body)=>{
  return AuthPostCall(HouzdFetchHPIDetails,Body)
}


const PostCall=(Endpoint,Body)=>{

}
const GetCall=(Endpoint)=>{
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  return fetch(Endpoint, requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      return result;
    })
    .catch(error => {
      console.log('error', error)
      return null
    });
}
const AuthPostCall=(Endpoint,Body)=>{
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "Bearer "+Fetch_Auth_Code());

var raw = JSON.stringify(Body);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

return fetch(Endpoint, requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    return result;
  })
  .catch(error => {
    console.log('error', error)
    return null
  });
}