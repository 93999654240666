import {
  Get_Storage,
  GetDaysDifference,
  Set_Storage,
  GetCurrentFormatedDate,
  SplitArrayByValue,
  SortByDateAsc,
  SortByDateDesc,
  AddIndexToSortedArray,
  GetMode,
  CalculatingAVMEach_Month,
  delay,
} from "../Utils/GlobalFunctions";
import { ForecastHpiArray, HistoryHpiArray } from "../Utils/TempData";
import { useEffect, useState } from "react";
import { LoaderMain, NoMatchingHistory, SomethingWentWrong, SomethingWentWrong_Screen } from "./Loader";
import {
  HouzdUpdateAVM_Call,
  HouzdUpdateContact_Call,
  HouzdRetrieve_HPI,
} from "../Utils/ApiCalls";
import { toast } from "react-hot-toast";
import { Data_LineTemp } from "../Utils/TempData";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { DateTime } from "luxon";


import { amortizationSchedule } from "amortization";

const colors = ["#E0F2FD", "#01454e", "#648183"];
const hexToRgba = (hexCode, opacity) => {
  const r = parseInt(hexCode.substring(1, 3), 16);
  const g = parseInt(hexCode.substring(3, 5), 16);
  const b = parseInt(hexCode.substring(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const MainGraph = (props) => {
  var [MainItem,setMainItem]=useState({})
  useEffect(()=>{
    console.log("---------------------------------------------------------",props.ContactPersonDetails);
    setMainItem(props.ContactPersonDetails)

  },[props.ContactPersonDetails])
  useEffect(()=>{
    console.log("---------------------------------------------------------",props.ContactPersonDetails);
    setMainItem(props.ContactPersonDetails)
  
  },[])
  return (
    <>
    {MainItem.AVM_Date==null||MainItem.AVM_Date==undefined?
    <></>
    :
    <div className="p-4 CustomSizedRow m-0 d-flex justify-content-center">
    <div className="row mainGraphRow px-0">
      <div className="col-lg-12 col-12 text-center">
        <h4>The Estimate Value of Your Home is</h4>
      </div>
      <p className="col-lg-12 col-12 d-flex justify-content-center bottomDivider"></p>
      <div className="col-lg-12 col-12  d-flex justify-content-center align-items-center flex-column">
        <h1 className="mainPrimaryColor">
          $
          {MainItem.AVM == undefined
            ? ""
            : MainItem.AVM.toLocaleString()}
        </h1>
        <h5>
          $
          {MainItem.AVM_Lower == undefined
            ? ""
            : MainItem.AVM_Lower.toLocaleString()}{" "}
          to $
          {MainItem.AVM_Upper == undefined
            ? ""
            : MainItem.AVM_Upper.toLocaleString()}
        </h5>
      </div>
      <div className="col-lg-12 col-12">
        <AVMValueChart />
      </div>
    </div>
  </div>
    }
    </>
   
  );
};

const CurrentValueSec = (props) => {
  const [SectionState, setSectionState] = useState([]);
  const [PercentageVal, setPercentageVal] = useState(0);
  const [NetworthVal, setNetworthVal] = useState(0);

  useEffect(() => {
    var AVMObject = Get_Storage("UserContactInfo");
    NetWorthCalc(AVMObject);
  }, []);

  useEffect(() => {
    if (props.StatsStatus == true) {
      // alert("Here")
      var AVMObject = Get_Storage("UserContactInfo");
      console.log(AVMObject);
      NetWorthCalc(AVMObject);
    }
  }, [props.StatsStatus]);

  const NetWorthCalc = (AVMObject) => {
    if (AVMObject == null || AVMObject == undefined) {
      setSectionState(false);
      // alert("Here1")
    } else {
      try {
        AVMObject = JSON.parse(AVMObject);
        if (
          AVMObject.Purchase_Price == undefined ||
          AVMObject.Purchase_Price == null
        ) {
          setSectionState(false);
          // alert("Here2")
        } else {
          try {
            var AVM_Price = AVMObject.AVM;
            var Purchase_Price = AVMObject.Purchase_Price;
            var Percentage_Difference = (
              ((AVM_Price - Purchase_Price) / Purchase_Price) *
              100
            ).toFixed(2);
            if (Percentage_Difference > 0) {
              setPercentageVal(Percentage_Difference);
              console.log(
                "Purchase: " +
                  AVMObject.Purchase_Price +
                  " AVM: " +
                  AVMObject.AVM +
                  " = " +
                  Percentage_Difference
              );

              if (
                AVMObject.Interest_Rate == null ||
                AVMObject.Loan_Amount == null ||
                AVMObject.Term == null ||
                AVMObject.Recorded_Date == null
              ) {
                setSectionState(false);
                // alert("Here6")
              } else {
                try {
                  var annualInterestRate = AVMObject.Interest_Rate; // Annual interest rate in percentage
                  var loanAmount = AVMObject.Loan_Amount; // Loan amount in dollars
                  var loanTermInYears = AVMObject.Term==null||AVMObject.Term==undefined?0:AVMObject.Term/12; // Loan term in years
                  var loanRecordedDate = new Date(AVMObject.Recorded_Date);

                  var currentDate = new Date();
                  // //alert(currentDate)
                  // Calculate total number of months since the loan recorded date
                  var monthsSinceLoanRecorded =
                    (currentDate.getFullYear() -
                      loanRecordedDate.getFullYear()) *
                      12 +
                    (currentDate.getMonth() - loanRecordedDate.getMonth());
                    
                  // alert(loanAmount+" "+ Math.round(loanTermInYears)+" "+  annualInterestRate)
                  var amortizationTimeFrame = amortizationSchedule(
                    loanAmount,
                    Math.round(loanTermInYears),
                    annualInterestRate
                  );
                  console.log(
                    amortizationTimeFrame[monthsSinceLoanRecorded - 1]
                  );
                  // var PrincipleBalance =amortizationTimeFrame[monthsSinceLoanRecorded - 1].principalBalanceRounded;//prev month
                  var PrincipleBalance =
                    amortizationTimeFrame[monthsSinceLoanRecorded]
                      .principalBalanceRounded;

                  // //alert(`Outstanding Loan Amount as of ${currentDate.toDateString()}: $${PrincipleBalance.toFixed(2)}`);
                  var Equity = AVM_Price - PrincipleBalance;
                  var Net_Worth = Equity + Purchase_Price;
                  setNetworthVal(Equity);
                  setSectionState(true);
                } catch (ex) {
                  console.error(ex);
                  // alert("Here7")
                  setSectionState(false);
                }
              }
            } else {
              setSectionState(false);
              // alert("Here3")
            }
          } catch (ex) {
            console.error(ex);
            setSectionState(false);
            // alert("Here4")
          }
        }
      } catch {
        setSectionState(false);
        //alert("Here5")
      }
    }
  };

  return (
    <>
      {SectionState ? (
        <div className="row">
          <div className="col-lg-12 col-12 d-flex justify-content-center align-items-center ">
            <p className="boldFont">
              This is upto <span className="emailColor">{PercentageVal}%</span>{" "}
              from when you bought it. This means the equity in your home is now 
            </p>
          </div>
          <div className="col-lg-12 col-12 emailColor d-flex justify-content-center align-items-center ">
            <h2>${NetworthVal.toLocaleString()}</h2>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* //temp */}
      <div className="row">
        <div className="col-lg-12 col-12 d-flex justify-content-center align-items-center ">
          <p className="boldFont hiddenText">
            This is upto <span className="emailColor">9%</span> from when you
            bought it. This means the net worth of the home is now
          </p>{" "}
        </div>
      </div>
      {/* //temp */}
    </>
  );
};

const AVMValueChart = () => {
  const [CurrentAVM_Obj, setCurrentAVM_Obj] = useState("LoaderStart");
  const [DataArray, setDataArray] = useState([]);
  const [LabelArray, setLabelArray] = useState([]);
  const [UpdateStats, setUpdateStats] = useState(false);
  const [ButtonState_Verify,setButtonState_Verify]=useState(false)

  useEffect(() => {
    FormatAVM_Object();
  }, []);

  const FormatAVM_Object = () => {
    var AVMObject = Get_Storage("UserContactInfo");
    console.log(AVMObject);
    var DaysDifference = 0;
    if (AVMObject == null || AVMObject == undefined) {
    } else {
      try {
        AVMObject = JSON.parse(AVMObject);
      } catch {}
      if (AVMObject.AVM_Date == undefined) {
      } else {
        DaysDifference = GetDaysDifference(AVMObject.AVM_Date);
      }
      console.log(DaysDifference + " Days");
      // DaysDifference = 1111;
      if (DaysDifference > 120) {
        //get Latest and update contact values
        console.log("Getting Updated");
        GettingLatest_AVM();
      } else {
        //use the old values
        SettingCurrentAVM(AVMObject);
        //work for map
        FetchingMapYears();
      }
    }
  };

  const SettingCurrentAVM = (AVMObject) => {
    var AVMObj_Temp = AVMObject;
    AVMObj_Temp["AVM"] = AVMObject.AVM;
    AVMObj_Temp["AVM_Date"] = AVMObject.AVM_Date;
    AVMObj_Temp["AVM_Lower"] = AVMObject.AVM_Lower;
    AVMObj_Temp["AVM_Upper"] = AVMObject.AVM_Upper;
    AVMObj_Temp["AVM"] = AVMObject.AVM;
    AVMObj_Temp["Mailing_Street"] = AVMObject.Mailing_Street;
    AVMObj_Temp["Mailing_ZipCode"] = AVMObject.Mailing_Zip;
    Set_Storage("UserContactInfo", JSON.stringify(AVMObj_Temp));
    //get history and forecast from state
    setCurrentAVM_Obj(AVMObject);
    console.log(AVMObj_Temp);
  };
  const ToggleBtnState_Disable=(state)=>{
    setButtonState_Verify(state)
  }

  const GettingLatest_AVM = () => {
    ToggleBtnState_Disable(true)
    toast.loading("Please Wait")
    
    var AVMObject = Get_Storage("UserContactInfo");
    AVMObject = JSON.parse(AVMObject);
    var CurrentAddress = AVMObject.Mailing_Street;
    var CurrentZip = AVMObject.Mailing_Zip;
    var mainBody = {
      CurrentAddress: CurrentAddress,
      CurrentZip: CurrentZip,
    };

    if (GetMode()) {
      mainBody = {
        CurrentAddress: "6823 Ridgeway Cir",
        CurrentZip: "80134",
      };
    }

    HouzdUpdateAVM_Call(mainBody)
      .then((data) => {
        console.log(data);
        if (data != null && data.Status == "Success") {
          var AVMObject_temp = JSON.parse(Get_Storage("UserContactInfo"));
          AVMObject_temp["AVM_Upper"] = data.Details.AVM_Upper;
          AVMObject_temp["AVM_Lower"] = data.Details.AVM_Lower;
          AVMObject_temp["AVM"] = data.Details.AVM;
          AVMObject_temp["AVM_Date"] = GetCurrentFormatedDate();
          //updating contact with AVM values
          UpdatingContactValue(AVMObject_temp);
        } else {
          ToggleBtnState_Disable(false)
          toast.dismiss()
          setCurrentAVM_Obj("Failure");
        }
      })
      .catch((e) => {
        ToggleBtnState_Disable(false)
        toast.dismiss()
        setCurrentAVM_Obj("Failure");
      });
  };

  const UpdatingContactValue = (AVMObject_temp) => {
    var Body_UpdateContact = {
      ContactID: Get_Storage("Contact_Ref"),
      AVM: AVMObject_temp["AVM"],
      AVM_Upper: AVMObject_temp["AVM_Upper"],
      AVM_Lower: AVMObject_temp["AVM_Lower"],
      AVM_Date: AVMObject_temp["AVM_Date"],
    };
    console.log(AVMObject_temp);
    console.log(Body_UpdateContact);
    HouzdUpdateContact_Call(Body_UpdateContact)
      .then((resp) => {
        if (resp != null && resp.Status == "Success") {
          try {
            var ContactObj = JSON.parse(resp.Details);
            if (ContactObj.Status == "Success") {
              Set_Storage("UserContactInfo", JSON.stringify(ContactObj));
              SettingCurrentAVM(ContactObj);
              //work for map
              FetchingMapYears();
              setUpdateStats(true);
              ToggleBtnState_Disable(false)
              toast.dismiss()
              toast.success('Latest AVM Recorded!')
              setTimeout(() => {
                setUpdateStats(false);
              }, 200);
            } else {
              ToggleBtnState_Disable(false)
              toast.dismiss()
              setCurrentAVM_Obj("Failure");
            }
          } catch {
            ToggleBtnState_Disable(false)
            toast.dismiss()
            setCurrentAVM_Obj("Failure");
          }
        } else {
          ToggleBtnState_Disable(false)
          toast.dismiss()
          setCurrentAVM_Obj("Failure");
        }
      })
      .catch((e) => {
        console.log(e);
        ToggleBtnState_Disable(false)
        toast.dismiss()
        setCurrentAVM_Obj("Failure");
      });
  };

  const FetchingMapYears = async () => {
    //changes to be made here API CALL for HPIS
    setDataArray(null);

    var MainBody = {
      Hpi_State: JSON.parse(Get_Storage("UserContactInfo")).Mailing_State,
    };
    console.log(MainBody);

    var respHPI = await HouzdRetrieve_HPI(MainBody);
    // var FinalData = HistoryHpiArray;
    var FinalData = [];

    if (respHPI != null) {
      if (respHPI.Status == "Success") {
        FinalData = respHPI.Details;
      } else {
        FinalData = [];
      }
    } else {
      FinalData = [];
    }
    //fetch forecast and history array here

    //work on this data
    SortByDateAsc(FinalData);

    var valueToSplit = GetCurrentFormatedDate();

    valueToSplit = valueToSplit.substring(0, 7);
    console.log(valueToSplit);

    var [firstHalf, secondHalf] = await SplitArrayByValue(
      FinalData,
      valueToSplit
    );

    //Future
    SortByDateDesc(firstHalf);
    SortByDateAsc(secondHalf);
    console.log(firstHalf);
    console.log(secondHalf);

    firstHalf=await CalculatingAVMEach_Month(firstHalf);

    secondHalf=await CalculatingAVMEach_Month(secondHalf);
    FinalData = [...firstHalf, ...secondHalf];
    SortByDateDesc(FinalData);
    console.log(FinalData);
    // Create an object to store the yearly averages
    const yearlyAverages = {};

    const uniqueMonths = {}; // Helper object to store unique month values

    const filteredData = FinalData.filter((item) => {
      const { month } = item;
      if (!uniqueMonths[month]) {
        uniqueMonths[month] = true;
        return true; // Include the item in the filtered array
      }
      return false; // Exclude the item from the filtered array
    });

    FinalData = filteredData;
    console.log(FinalData);
    SortByDateAsc(FinalData);

    // Calculate the average HPI value for each year
    // FinalData.forEach((entry) => {
    //   const year = entry.month.substring(0, 4);
    //   if (!yearlyAverages.hasOwnProperty(year)) {
    //     yearlyAverages[year] = {
    //       total: 0,
    //       count: 0,
    //       total_real: 0,
    //       total_trend: 0,
    //     };
    //   }
    //   yearlyAverages[year].total += entry.AVM_Value;
    //   yearlyAverages[year].total_real += entry.AVMLower_Value;
    //   yearlyAverages[year].total_trend += entry.AVMUpper_Value;

    //   yearlyAverages[year].count++;
    // });

    var FinalYearList = [];
    var FinalLineDataList = [];
    for (const Record in FinalData) {
      var MainRecord=FinalData[Record]
      // console.log(MainRecord)
        var FinalObj = {};

      var DataLineArrayRow = [];
      DataLineArrayRow.push(MainRecord.month.slice(0, 7));
      DataLineArrayRow.push(MainRecord.AVM_Value.toFixed(2));

      FinalObj["Year"] = MainRecord.month.slice(0, 7);
      FinalObj["AVM_Average"] = MainRecord.AVM_Value.toFixed(2);

      FinalYearList.push(FinalObj);
      FinalLineDataList.push(DataLineArrayRow);
    }
    // Calculate and display the average HPI value for each year
    // for (const year in yearlyAverages) {
    //   const average = yearlyAverages[year].total / yearlyAverages[year].count;
    //   const average_real =
    //     yearlyAverages[year].total_real / yearlyAverages[year].count;
    //   const average_trend =
    //     yearlyAverages[year].total_trend / yearlyAverages[year].count;
    //   var FinalObj = {};
    //   var DataLineArrayRow = [];
    //   DataLineArrayRow.push(year);
    //   DataLineArrayRow.push(average.toFixed(2));
    //   // DataLineArrayRow.push(average_real.toFixed(2))
    //   // DataLineArrayRow.push(average_trend.toFixed(2))
    //   FinalObj["Year"] = year;
    //   FinalObj["AVM_Average"] = average.toFixed(2);
    //   // FinalObj["AVMLower_Average"]=average_real.toFixed(2);
    //   // FinalObj["AVMUpper_Average"]=average_trend.toFixed(2);
    //   FinalYearList.push(FinalObj);
    //   FinalLineDataList.push(DataLineArrayRow);
    // }
    // var LabelArray_Temp=["AVM","AVM Lower","AVM Upper"];
   
    var LabelArray_Temp = ["AVM"];

    setLabelArray(LabelArray_Temp);
    console.log(FinalYearList);
    setDataArray(FinalLineDataList);
  };

  return (
    <div className="row d-flex justify-content-center align-items-center graphRow flex-column">
      <div className="col-lg-12 col-12 text-center">
        <button
          // className={ButtonState_Verify?"btn btn-warning disabled":"btn btn-warning"}
          className="d-none"
          style={{ color: "white" }}
          onClick={() => {
            GettingLatest_AVM();
          }}
        >
          <i className="fa-regular fa-circle-check"></i> Verify This
        </button>
        <div className="GraphSecWidth">
          {CurrentAVM_Obj == "LoaderStart" ? (
            <LoaderMain />
          ) : CurrentAVM_Obj == "Failure" ? (
            <SomethingWentWrong/>
            ) : (
            <LineChart
              data={DataArray}
              labels={LabelArray}
              legend
              area
            />
          )}
        </div>
      </div>

      <div className="col-lg-12 col-12 d-flex justify-content-center align-items-center graphRow flex-column">
        <CurrentValueSec StatsStatus={UpdateStats} />
      </div>
    </div>
  );
};

export default function LineChart(props) {
  const screenWidth = window.innerWidth;



  const { data, labels, area, legend } = props;
  if(data==null){
    return<LoaderMain/>
  }
  else if (data.length == 0) {
    return<><NoMatchingHistory/></>
  } else {
    //will format yaxis//change here
    const formatYAxisLabel = (value) => {
  if(screenWidth>500){    if (value >= 1000000) {
        // Convert to millions format (e.g., 1M, 1.2M)
        return `$${(value / 1000000).toFixed(1)}M`;
      } else if (value >= 1000) {
        // Convert to thousands format (e.g., 800K, 1.2K)
        return `$${(value / 1000).toFixed(1)}K`;
      }
      }
      else{
        if (value >= 1000000) {
          // Convert to millions format (e.g., 1M, 1.2M)
          return `$${(value / 1000000).toFixed(0)}M`;
        } else if (value >= 1000) {
          // Convert to thousands format (e.g., 800K, 1.2K)
          return `$${(value / 1000).toFixed(0)}K`;
        }
      }
      return value.toString();
    };
    var prevYear="";
    const formatXAxisLabel = (value) => {
      const year = value.split('-')[0];
      if(screenWidth>500){
        if(prevYear == "" ){
          prevYear = year;
          return year;
        }
      else if ((year !== prevYear)&&(value.includes("06"))) {
        prevYear = year;
        return year;
      } else {
        return ''; // Empty string to skip the label
      }
      }
      else{
return year
      }

    };
    // Function to generate the initial selected legend items
    const generateSelectedLegendItems = () => {
      const selected = {};
      data[0].forEach((_, index) => {
        if (index == 0) {
          selected[`AVM Lower`] = true; // Select the first dataset by default
          selected[`AVM Upper`] = true; // Select the first dataset by default
        } else {
          selected[`AVM Lower`] = false;
          selected[`AVM Upper`] = false; // Select the first dataset by default
        }
      });
      return selected;
    };

    const dataIndicies = Array.from(Array(data[0].length).keys());
    dataIndicies.shift();

    const series = dataIndicies.map((seriesIndex) => {

      return {
        name: labels && labels[seriesIndex - 1],
        type: "line",
        showSymbol: true,
        data: data.map((timePoint) => timePoint[seriesIndex]),
     
        smooth: 0,
        symbol: 'none',
        lineStyle: {
          color: "#01A0E9",
          width: 5
        },
        markArea: {
          silent:true,
          label:{
            show:true,
            position:"bottom",
            offset:[10, -40],
            fontSize:screenWidth>500?18:12,
            color:"#000",

            },

          
          itemStyle: {
            color: 'white'
          },
          data: [
            [
              {
                name: 'Past 5 Years Growth',
                xAxis: data[0][0]
              },
              {
                xAxis: data[60][0]
              }
            ],
            [
              {
                name: '3 Years Growth',
                xAxis: data[60][0]
              },
              {
                xAxis: data[95][0]
              }
            ]
          ]
        },
        areaStyle: {},
      };
    });
    //will format options//change here
    const option={
      title: [
        {
          text: "",
        },
      ],
      tooltip: {
        trigger: "axis",
        triggerOn: "none",

        show:false
      },
      legend: {
        show: legend,
        left: 10,
        bottom: 0,
        orient: "horizontal",
        icon: "circle",
        textStyle: {
          fontSize: 14,
          lineHeight: 24,
        },
        selected: generateSelectedLegendItems(),
      },
      xAxis: [
        {   
          boundaryGap: false,
          type: 'category',

          data: data.map((timePoint) => timePoint[0]),
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          
          axisLabel: screenWidth>500?
          {
            interval: 0, // Set a large number (e.g., 0) to disable auto adjustment

            margin: 15,
            fontSize: 16,
            fontWeight: 600,
            formatter: formatXAxisLabel,

          }
          :
          {
            // interval: screenWidth>500?0, // Set a large number (e.g., 0) to disable auto adjustment

            margin: 15,
            fontSize: 12,
            fontWeight: 600,
            formatter: formatXAxisLabel,

          }
          
          ,
          axisPointer: {
            value: new Date().toISOString().slice(0, 7),
            snap: true,
            triggerOn:"none",
            lineStyle: {
              color: "#aad2ffcc",
              width: 2,
              type: "solid",
            },
            label: {
              show: true,
              formatter: function (params) {
                var mainVal=data.filter((timePoint) => timePoint[0] == params.value)[0][1]
                return (
                  //    "Estimated Average Value for\n Year " +
                  //  params.value +
                  //  " is $" +
                  //  data.filter((timePoint) => timePoint[0] == params.value)[0][1]
                "$ "+parseInt(mainVal).toLocaleString()
                );
                // +" for year "+echarts.format.formatTime('yyyy', params.value));
              },
              
              backgroundColor: "#aad2ffcc",
              margin: -400,
              fontSize: 14,
              padding: 12,
              lineHeight: 20,
              borderRadius: 8,
              color:"#000000b9",
              borderColor :"#000"
            },
            handle: {
              show: true,
              color: "#00A8BD",
              size: 0,
            },
          },
        },
      ],
      yAxis: [
        {
          splitLine: {
            lineStyle: {
              color: "#ececec",
            },
          },
          boundaryGap: false,
          axisLabel: {
            margin: 20,
            fontSize: screenWidth>500?16:12,
            fontWeight: 600,
            formatter: formatYAxisLabel,
          },
        },
      ],
      grid: {
        left: "2%",
        right: "5%",
        bottom: legend ? "10%" : 0,
        containLabel: true,
      },
     

      visualMap: {
        type: 'piecewise',
        show: false,
        dimension: 0,
        seriesIndex: 0,
        pieces: [
          {
            gt: 0,
            lt: 60,
            color: '#e0f3fc',

          },
          {
            gt: 60,
            lt: 95,
            color: '#e0f3fc'
          }
        ]
      },
      



      series,
    };

    



    return <ReactECharts option={option} style={{ height: 525 }} />;
  }
}

export const SecondaryGraph = (props) => {
  const [RecordData, setRecordData] = useState(props.ContactPersonDetails);
  const [SectionState, setSectionState] = useState(false);

  useEffect(() => {
    setRecordData(props.ContactPersonDetails);
    CheckStatusGraph();
  }, [props.ContactPersonDetails]);

  const CheckStatusGraph = () => {
    if (RecordData == null || RecordData == undefined) {
      setSectionState(false);
    } else {
      if (
        RecordData.Interest_Rate == null ||
        RecordData.Loan_Amount == null ||
        RecordData.Term == null ||
        RecordData.Recorded_Date == null
      ) {
        setSectionState(false);
      } else {
        setSectionState(true);
      }
    }
  };



  function formatDateToUSLocale(inputDate) {
    console.log("Date Working.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-")
    console.log(inputDate)
    var isoString = new Date(inputDate).toISOString();

    const date = DateTime.fromISO(isoString, { zone: 'utc' });

    const formattedDate = date.toFormat('LLL, yyyy'); // Format to 'Jan, 2022'

    console.log(formattedDate); // Outputs: 'Jan, 2022'
 
    console.log("Date Working.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-.-.-.--.-.-.-.-.-")
    return formattedDate;
  }
  
  return (
    <>
      {SectionState ? (
        <div className="p-4 CustomSizedRow m-0 d-flex justify-content-center">
          <div className="row mainGraphRow secondaryGraphRow">
            <div className="col-lg-12 col-12 d-flex justify-content-center">
              <h4>What You've paid so far</h4>
            </div>
            <p className="col-lg-12 col-12 d-flex justify-content-center bottomDivider"></p>
            <div className="col-lg-12 col-12">
              <PieChart RecordDetails={RecordData} />
            </div>
            <div className="col-lg-12 col-12 d-flex justify-content-center align-items-center graphRow ">
              <span className="BottomHeading">
                Calculation Based on{" "}
                <strong>${RecordData.Loan_Amount.toLocaleString()}</strong>{" "}
                original loan amount at{" "}
                <strong>{RecordData.Interest_Rate}%</strong> interest rate over
                a <strong>{RecordData.Term==null||RecordData.Term==undefined?0:RecordData.Term/12}</strong>-Year period starting from{" "}
                <strong>
                  {formatDateToUSLocale(RecordData.Recorded_Date)}
                </strong>
                .
              </span>
            </div>
            <div className="col-lg-12 col-12  d-flex justify-content-center align-items-center graphRow emailColor ">
              <button className="btn btn-warning updateBtn d-none">
                Update this number
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export function PieChart(props) {
  var [PieOption, setPieOptions] = useState({});
  var [PrincipleValue, setPrincipleValue] = useState(2000000);
  var [InterestValue, setInterestValue] = useState(2000000);

  useEffect(() => {
    SettingOptions(props.RecordDetails);
  }, []);
  useEffect(() => {
    SettingOptions(props.RecordDetails);
  }, [props]);

  const SettingOptions = (RecordDetails) => {
    var PrinciplePercentage = 0;
    var InterestPercentage = 0;

    console.log("Record Details=====================================");
    console.log(RecordDetails);
    if (RecordDetails != {}) {
      console.log("Loan Amount", RecordDetails.Loan_Amount);
      console.log("Interest Rate", RecordDetails.Interest_Rate);
      console.log("Loan Term", RecordDetails.Term==null||RecordDetails.Term==undefined?0:RecordDetails.Term/12);
      try {
        var annualInterestRate = RecordDetails.Interest_Rate; // Annual interest rate in percentage
        var loanAmount = RecordDetails.Loan_Amount; // Loan amount in dollars
        var loanTermInYears =RecordDetails.Term==null||RecordDetails.Term==undefined?0:RecordDetails.Term/12; // Loan term in years

        var loanRecordedDate = new Date(RecordDetails.Recorded_Date);

        var currentDate = new Date();
        // //alert(currentDate)
        // Calculate total number of months since the loan recorded date
        var monthsSinceLoanRecorded =
          (currentDate.getFullYear() - loanRecordedDate.getFullYear()) * 12 +
          (currentDate.getMonth() - loanRecordedDate.getMonth());

        var amortizationTimeFrame = amortizationSchedule(
          loanAmount,
          loanTermInYears,
          annualInterestRate
        );
        console.log(amortizationTimeFrame);
        console.log(amortizationTimeFrame[monthsSinceLoanRecorded]);
        var DetailsList = amortizationTimeFrame.splice(
          0,
          monthsSinceLoanRecorded
        );
        console.log(DetailsList);
        var PrincipleAmount = 0;
        var InterestAmount = 0;

        for (var i = 0; i < DetailsList.length; i++) {
          PrincipleAmount =PrincipleAmount + DetailsList[i].principalPaymentRounded;
          InterestAmount =InterestAmount + DetailsList[i].interestPaymentRounded;
        }
        setPrincipleValue(PrincipleAmount);
        setInterestValue(InterestAmount);
        var TotalAmount = PrincipleAmount + InterestAmount;
        PrinciplePercentage = (PrincipleAmount / TotalAmount) * 100;
        InterestPercentage = (InterestAmount / TotalAmount) * 100;
      } catch {}
    }
    const colors = ['#107284', '#dcdcdc'];

    var option = {
      title: {
        text: "",
        subtext: "",
        left: "center",
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>Towards {b} : {d}%",
      },
      legend: {
        // orient: 'vertical',
        // top: 'middle',
        bottom: 10,
        show: false,
        left: "center",
        data: ["Interest", "Principle"],
      },
      series: [
        {
          name: "",
          type: "pie",
          
          radius: "65%",
          center: ["50%", "50%"],
          selectedMode: "single",
          selectedOffset: 10, 
          data: [
            { value: InterestPercentage, name: "Interest" ,selected: true},
            { value: PrinciplePercentage, name: "Principle" },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            }
          },
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
            normal: {
              color: function(params) {
                // Use the defined colors cyclically
                return colors[params.dataIndex];
              },
            },
          },
          label: {
            normal: {
              formatter: "{b}",
              position: "inside",
              fontSize: 15,
            },
          },
        },
      ],
    };

    setPieOptions(option);
  };
  return (
    <div className="row">
      <div className="col-lg-6 col-6 text-center d-flex justify-content-center align-items-center">
        <div className="SmallSecs">
          <h5>Towards Principle</h5>
          <h2>${PrincipleValue.toLocaleString()}</h2>
        </div>
      </div>

      <div className="col-lg-6 col-6 text-center d-flex justify-content-center align-items-center">
        <div className="SmallSecs">
          <h5>Towards Interest</h5>
          <h2>${InterestValue.toLocaleString()}</h2>
        </div>
      </div>
      <div className="col-lg-12 col-12 d-flex justify-content-center">
        <ReactECharts option={PieOption} style={{ height: 525 }} />
      </div>
    </div>
  );
}
