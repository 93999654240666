import { useEffect, useState } from "react";
import { Header } from "./Components/Header";
import { ContactDetails } from "./Components/ContactDetails";
import { MainGraph, SecondaryGraph } from "./Components/Graphs";
import { InterestDetails } from "./Components/InterestDetails";
import { RfiScreens } from "./Components/RFIPlanScreens";
import "./Css/Global.css";
import "./Css/Responsive.css"

import { LoaderMain ,SomethingWentWrong_Screen,InvalidContactID_Screen,NoMatchingContact, LoaderFull} from "./Components/Loader";
import { HouzdVerifier_Call,HouzdContactDetails_Call } from "./Utils/ApiCalls";
import { Set_Auth_Code ,GetMode,Set_Storage} from "./Utils/GlobalFunctions";
import storageChanged from 'storage-changed'
import { Disclaimer_Main } from "./Components/Loader";
function App() {
  const [ContactInfoObj, setContactInfoObj] = useState({});
  const [LoaderState, setLoaderState] = useState("LoaderStart"); //set false to hide loader

  useEffect(() => {
    if(GetMode()){     
    var ContactObj = {
      Status: "Success",
      AVM: 563000,
      AVM_Date: "2023-05-19",
      AVM_Lower: 534850,
      AVM_Upper: 591150,
      Full_Name: "Matthew Watkins",
      Owner_Details: {
        Name: "Anthony VanDyke",
        phone: "801-206-4343",
        email: "anthony.vandyke@houzd.com",
        nmlsNumber: 247102,
        profileImage:"https://contacts.zoho.com/file?ID=694263673"
      },
    };
    if (ContactObj["Status"] == "Success") {
      SettingObject_Contact(ContactObj)


    }
    }
    else{
      var val=Extracting_ContactID()
      if(val!==null){
          VerifingHouzd(val)
          Set_Storage("Contact_Ref",val)
        }
        else{
          setLoaderState("NoIDFound");
        }
  
    }



    storageChanged('local', {
      eventName: 'ContactObjectStorageChanged'
    })
    window.addEventListener('ContactObjectStorageChanged', (event) => {
      console.log(event)
      if(event.detail.key=="UserContactInfo"){
        setContactInfoObj(JSON.parse(event.detail.value));
      }
    })
     
    
  }, []);

  const SettingObject_Contact=(ContactObj)=>{
    Set_Storage("UserContactInfo", JSON.stringify(ContactObj));
    setContactInfoObj(ContactObj);
    setLoaderState("LoaderStop");
  }

  const VerifingHouzd=(val)=>{
    HouzdVerifier_Call().then((data)=>{
      if(data!=null&&data.Status=="Success"){
        Set_Auth_Code(data.JToken);
        HouzdContactDetails_Call(val).then(resp=>{
          if(resp!=null&&resp.Status=="Success"){
            try{
              var ContactObj=JSON.parse(resp.Details)
              if(ContactObj.Status=="Success"){
                SettingObject_Contact(ContactObj)
              }
              else{
                setContactInfoObj({});
                Set_Storage("UserContactInfo", JSON.stringify({}));

                setLoaderState("NoMatchingContact");

              }
            }
            catch{
              Set_Storage("UserContactInfo", JSON.stringify({}));
              setContactInfoObj({});
              setLoaderState("Failure");

            }
          }
          else{
            setContactInfoObj({});
            Set_Storage("UserContactInfo", JSON.stringify({}));

            setLoaderState("Failure");

          }
        }).catch((e)=>{
          console.log(e)
          setContactInfoObj({});
          Set_Storage("UserContactInfo", JSON.stringify({}));

          setLoaderState("Failure");
        })
      }
      else{
        setContactInfoObj({});
        Set_Storage("UserContactInfo", JSON.stringify({}));

        setLoaderState("Failure");

      }

    }).catch((e)=>{
      console.log(e)
      setContactInfoObj({});
      Set_Storage("UserContactInfo", JSON.stringify({}));

      setLoaderState("Failure");

    })
  }

  const Extracting_ContactID=()=>{

  const url = new URL(window.location.href);
  // Get the search parameter value
  const searchParams = new URLSearchParams(url.search);
  const paramValue = searchParams.get('refID');

  console.log(paramValue);
  return paramValue
  }




  return (
    <div className="App">
      
      {LoaderState=="LoaderStart" ? (
        <LoaderFull />
      )
      :
      LoaderState=="NoIDFound"?<InvalidContactID_Screen />
      :
      LoaderState=="Failure"?<SomethingWentWrong_Screen />
      : 
      LoaderState=="NoMatchingContact"?<NoMatchingContact />
      :
     
      (
        <div className="row m-0 p-0">
          <div className="col-lg-12 col-12 p-0">
            <Header />
          </div>
          <div className="col-lg-12 col-12 p-0">
            <ContactDetails ContactPersonDetails={ContactInfoObj} />
          </div>
          <div className="col-lg-12 col-12 p-0">
            <MainGraph ContactPersonDetails={ContactInfoObj} />
          </div>
          <div className="col-lg-12 col-12 p-0">
            <SecondaryGraph ContactPersonDetails={ContactInfoObj} />
          </div>
          <div className="col-lg-12 col-12 p-0">
            <Disclaimer_Main />
          </div>

          <div className="col-lg-12 col-12 p-0 d-none">
            <InterestDetails ContactPersonDetails={ContactInfoObj} />
          </div>
          <div className="col-lg-12 col-12 p-0 d-none">
            <RfiScreens ContactPersonDetails={ContactInfoObj} />
          </div>
        </div>
      )
      
      }
    </div>
  );
}

export default App;
