export const Fetch_Auth_Code=()=>{

   return Get_Cookies("Auth_SS")

}
export const Set_Auth_Code=(value)=>{
    Set_Cookies("Auth_SS",value,1)
}

export const Set_Cookies=(Key,value,days)=>{
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = Key + "=" + value + expires + "; path=/";


}
export const Get_Cookies=(Key)=>{
    var nameEQ = Key + "=";
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      while (cookie.charAt(0) == ' ')
        cookie = cookie.substring(1, cookie.length);
      if (cookie.indexOf(nameEQ) === 0)
        return cookie.substring(nameEQ.length, cookie.length);
    }
    return null;
}


export const Set_Storage=(key,value)=>{
localStorage.setItem(key,value)
}
export const Get_Storage=(key)=>{
    return localStorage.getItem(key)
}
export const GetMode=()=>{
    if(window.location.href.includes("localhost")){
        return true
    }
    else{
        return false
    }
}

export const GetDaysDifference=(mainDate)=>{
    console.log(mainDate)
    const specificDate = new Date(mainDate);
    console.log(specificDate)
// Get the current date
const currentDate = new Date();

// Calculate the time difference in milliseconds
const timeDifference = currentDate.getTime() - specificDate.getTime();

// Convert the time difference to days
const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

console.log(daysDifference);
return daysDifference;
}

export const GetCurrentFormatedDate=()=>{
    const currentDate = new Date();

// Get the year, month, and day from the current date
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');

// Format the date in the desired format
const formattedDate = `${year}-${month}-${day}`;

console.log(formattedDate); // Output: 2023-06-01
return formattedDate;
} 
export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const SplitArrayByValue=async(array, splitValue)=> {
  var FinalArray=[];  

  for(var i=0;i<array.length;i++){
      var arrObj=array[i]
      if(arrObj.month.includes(splitValue)){
        var AVMVal=JSON.parse(Get_Storage("UserContactInfo")).AVM
        var AVMVal_Upper=JSON.parse(Get_Storage("UserContactInfo")).AVM_Upper
        var AVMVal_Lower=JSON.parse(Get_Storage("UserContactInfo")).AVM_Lower
        arrObj["AVM_Value"]=AVMVal
        arrObj["AVMLower_Value"]=AVMVal_Lower
        arrObj["AVMUpper_Value"]=AVMVal_Upper
        FinalArray.push(arrObj)
      }
      else{
        var AVMVal=0
        var AVMVal_Upper=0
        var AVMVal_Lower=0
        
        arrObj["AVM_Value"]=AVMVal
        arrObj["AVMLower_Value"]=AVMVal_Lower
        arrObj["AVMUpper_Value"]=AVMVal_Upper
        FinalArray.push(arrObj)
      }
    }
    console.log(FinalArray)
        
    const splitIndex = FinalArray.findIndex(obj => obj.month.includes(splitValue));
    

    console.log(splitIndex)
    if (splitIndex < 0) {
      // Split value not found in the array
      return [FinalArray, []];
    }
  
    const firstHalf = FinalArray.slice(0, splitIndex+1);
    const secondHalf = FinalArray.slice(splitIndex);
  
    return [firstHalf, secondHalf];
  }
 export const SortByDateAsc=(array)=> {
    array.sort((a, b) => {
      const dateA = new Date(a.month);
      const dateB = new Date(b.month);
      return dateA - dateB;
    });
  }
  export const SortByDateDesc=(array)=> {
    array.sort((a, b) => {
      const dateA = new Date(a.month);
      const dateB = new Date(b.month);
      return dateB - dateA;
    });
  }
  export const AddIndexToSortedArray=(array)=> {
    array.forEach((obj, index) => {
      obj.index = index+1;
    });
  }

  export const CalculatingAVMEach_Month=(array)=>{
var mainItemPivot={}
    for (let i = 0; i < array.length; i++) {
      
      var HPI_Value_Converted=1;
      var currentItem = array[i];
      var currentHPI = currentItem.hpi_value;
      var currentHPI_Real = currentItem.hpi_real==null||currentItem.hpi_real==undefined?100:currentItem.hpi_real;
      var currentHPI_Trend = currentItem.hpi_trend==null||currentItem.hpi_trend==undefined?100:currentItem.hpi_trend;
      var currentAVM=0;

      if(i==0){
        mainItemPivot=array[i]
        HPI_Value_Converted=1;
        
       }
      else{
        HPI_Value_Converted=currentHPI/mainItemPivot.hpi_value
      }
      currentAVM = HPI_Value_Converted*mainItemPivot.AVM_Value;
      
      currentItem.AVM_Value=currentAVM;

      currentItem.AVMLower_Value=0;
      currentItem.AVMUpper_Value=0;

      
      // var previousItem = array[i - 1];
        // var currentItem = array[i];
        
        // // Calculate the AVM using the formula
        // var currentHPI = currentItem.hpi_value;
        // var previousHPI = previousItem.hpi_value;
        // currentItem.AVM_Value = previousItem.AVM_Value / (currentHPI / previousHPI);



        // var currentHPI_Real = currentItem.hpi_real==null||currentItem.hpi_real==undefined?100:currentItem.hpi_real;
        // var previousHPI_Real = previousItem.hpi_real==null||previousItem.hpi_real==undefined?100:previousItem.hpi_real;

        // currentItem.AVMLower_Value = previousItem.AVMLower_Value / (currentHPI_Real / previousHPI_Real);
        
        
        // var currentHPI_Trend = currentItem.hpi_trend==null||currentItem.hpi_trend==undefined?100:currentItem.hpi_trend
        // var previousHPI_Trend = previousItem.hpi_trend==null||previousItem.hpi_trend==undefined?100:previousItem.hpi_trend

        // currentItem.AVMUpper_Value = previousItem.AVMUpper_Value / (currentHPI_Trend / previousHPI_Trend);

      }
      console.log(array);
      return array

  }