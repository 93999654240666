export const InterestDetails=(props)=>{
    return(
    <div className="row interestRow mainGraphRow p-4 CustomSizedRow m-0">
    <div className="col-lg-12 col-12 d-flex justify-content-center">
        <h4>What You've paid so far</h4>
    </div>
    <p className="col-lg-12 col-12 d-flex justify-content-center bottomDivider"></p>

    <div className="col-lg-12 col-12  d-flex justify-content-center align-items-center customRow_Small p-4 pb-0">
        <span className="boldFont text-center">Over your 30 year loan, you'll pay <span>$316,000</span> in interest. It actually happens to most people. Follow some tips and you could save a <br/> large part of that interest</span>
    </div>
    <div className="col-lg-12 col-12  d-flex justify-content-center align-items-center graphRow flex-column">
        <h5 className="text-center">If you pay just <span className="btn btn-success p-1">$200</span> Principal payment more each month<br/> you could save</h5>
        <br/><h2 className="emailColor">${props.ContactPersonDetails.AVM==undefined?"":props.ContactPersonDetails.AVM.toLocaleString()}</h2>


    </div>
    <div className="col-lg-12 col-12 d-flex justify-content-center align-items-center graphRow pt-4">
        <p>In interest over the rest of your load. You'd also be done with the load and no longer making payments <span className="text-success">6 Years earlier</span></p>    
    </div>
    <div className="col-lg-12 col-12 d-flex justify-content-center align-items-center graphRow">
        <p className="text-warning">Learn More</p>    
    </div>
</div>)
}