import React from "react";
import "../Css/ContactRow.css";

export const ContactDetails = (props) => {
  return (
    <div className="contactRowMain CustomSizedRow m-0">
      <div className="contactDivInner">
        <div className="row">
          <div className="col-lg-4 col-12">
          <div className="row justify-content-center text-center">

            <div className="col-lg-12 col-12 d-flex justify-content-center">
              <div className="
              image-container
              ">
              <img
                src={
                  props.ContactPersonDetails.Owner_Details == undefined?
                  "https://img.freepik.com/free-photo/close-up-girl-with-dreamy-thoughtful-face-looking-away-with-tender-smile_176420-25551.jpg"
                  :
                  props.ContactPersonDetails.Owner_Details.profileImage==""||props.ContactPersonDetails.Owner_Details.profileImage==undefined?
                  "https://img.freepik.com/free-photo/close-up-girl-with-dreamy-thoughtful-face-looking-away-with-tender-smile_176420-25551.jpg"
                  :
                  props.ContactPersonDetails.Owner_Details.profileImage}
                width={30}
                height={30}
                className="customImage"
              />

              </div>
            </div>
            <div className="col-lg-12 col-12 boldFont contactInfoDiv">
                      <h2>
                          {props.ContactPersonDetails.Owner_Details == undefined
                            ? ""
                            : props.ContactPersonDetails.Owner_Details.Name}
                        </h2>
                     
                      <div className="d-flex">
                      <a 
                      className="contactInfoSec" 
                      href={props.ContactPersonDetails.Owner_Details == undefined
                          ? "tel:"
                          : "tel:"+props.ContactPersonDetails.Owner_Details.phone}>
                      <i className="fa fa-phone"></i>
                      Call
                      
                      </a>
                
                      <a 
                      className="contactInfoSec" 
                      href={props.ContactPersonDetails.Owner_Details == undefined
                          ? "mailto:"
                          : "mailto:"+props.ContactPersonDetails.Owner_Details.email}>
                      <i className="fa-solid fa-envelope"></i>
                      Email
                        
                      </a>
                

                      </div>
                      <p className="NLMS">
                        NMLS#{" "}
                        {props.ContactPersonDetails.Owner_Details == undefined
                          ? ""
                          : props.ContactPersonDetails.Owner_Details.nmlsNumber}
                      </p>
            </div>

          </div>  
          </div>
          <div className="col-lg-8 col-12" style={{    justifyContent: "center",
    display: "flex",
    alignItems: "center"}}>
            <div className="row contactContentRow">
              <div className="col-lg-12 col-12">
                <h3 className="mainPrimaryColor">
                  Hi, <span className="">{props.ContactPersonDetails.Full_Name.split(" ")[0]}!</span>
                </h3>
              </div>
              <div className="col-lg-12 col-12 boldFont">
                <p>
                Below's your latest Houzd report, along with accompanying cost-saving recommendations.<br/> If you have any inquiries or require further clarification, please do not hesitate to reach out to our expert via phone or email. <br/>We are at your disposal for any assistance you may require.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
