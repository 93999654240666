import "../Css/Spinner.css"
import noresult from '../ImagesMain/noresult.png'
import invalid from '../ImagesMain/nocontent.png'
import error from '../ImagesMain/error.png'
import nocontent from '../ImagesMain/house.png'
import { Header } from "./Header"
export const LoaderMain=()=>{
    return(
        <div className="LoaderContainer">
<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
 )
}
export const LoaderFull=()=>{
    return(
        <div className="FullLoaderContainer">
<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
 )
}


export const SomethingWentWrong_Screen=()=>{
    return(
        <>
        <Header />
        <div  className="fallBack FullHeight">
       
       <img src={error}></img>
           <h3>Something Went Wrong</h3></div>
       </>
    )
}

export const InvalidContactID_Screen=()=>{
    return(
        <>
         <Header />
         <div  className="fallBack FullHeight" >
        
        <img src={invalid}></img>
            <h3>Invalid Contact ID</h3></div>
        </>
      
    )
}
export const NoMatchingContact=()=>{
    return(
        <>
        <Header />
        <div  className="fallBack FullHeight"> 
        
        <img src={nocontent}></img>
            <h5>Oops! We're sorry, but we couldn't find the value of your home.</h5> 
            <p>Thank you for your patience we will get it fixed and be in touch!</p>
        </div>
        </>
    )

}   
 export const NoMatchingHistory=()=>{
    return(
        <div  className="fallBack">
            
            <img src={noresult} className="ErrImage"></img>
            <h5>No Matching History and Forecase available for your State</h5>
            
            </div>
    )}
    export const SomethingWentWrong=()=>{
        return(
            <div  className="fallBack">
                
                <img src={error} className="ErrImage"></img>
                <h5>Something Went Wrong</h5>
                
                </div>
        )}
export const Disclaimer_Main=()=>{
    return(
        <div className="d-flex CustomSizedRow justify-content-center ">
            <div className="row disclaimerElement justify-content-start p-4">
                <h4 className="col-lg-12 col-12">
                    Disclaimer:

                </h4>
                <p className="col-lg-12 col-12 text-center">
                This home valuation report is for informational purposes only and not a substitute for a professional appraisal. The estimated value is based on various factors and may not reflect the exact market value. Actual values may differ due to local conditions, property state, and other unaccounted variables. It is recommended to consult a licensed real estate professional or certified appraiser for a comprehensive assessment. The provider assumes no liability for discrepancies or inaccuracies in the estimate. Users are advised to seek professional advice when making decisions based on property valuations, releasing the provider from any associated claims or liabilities.
                </p>
                </div>
                 
        </div>
    )
}